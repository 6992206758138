import React, { useState } from 'react';

// Components
import UserRegistrationSuccess from './UserRegistrationSuccess';
import BackToUserList from '../common/BackToUserList';
import InputValidation from '../validation/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidEmail, isEmpty } from '../../validation-utils/validationCheck';
import { setValidInput, setInvalidInput } from '../../validation-utils/toggleValidationFeedback';
import { createUser } from '../../utils/services';

// used to show agent input fields
const inputs = 
[ { group:'first-name-input-group', id:'first-name-input', placeholder: 'First name'   , name:'firstName', label:'First Name' }
, { group:'last-name-input-group' , id:'last-name-input' , placeholder: 'Last name'    , name:'lastName' , label:'Last Name'  }
, { group:'email-input-group'     , id:'email-input'     , placeholder: 'Email address', name:'email'    , label:'Email Address' }
];

// used to list role checkboxes
const roles = 
[ { text:'This agent is an administrator' , name:'isAdmin'}
, { text:'Agent can auto enroll members'  , name:'isAutoEnroller'}
, { text:'Agent can revoke enrollments'   , name:'isRevoker'}
, { text:'Agent can view reports'         , name:'isReportViewer'}
, { text:'Non-whitelisted access allowed' , name:'isNonWhitelistAccessAllowed'}
];

const UserRegistration = () => {
  const [formData, setFormData] = useState(
    { firstName                   : ''
    , lastName                    : ''
    , email                       : ''
    , isAdmin                     : false
    , isAutoEnroller              : false
    , isRevoker                   : false
    , isReportViewer              : false
    , isNonWhitelistAccessAllowed : true
    }
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const onChange = (e) => {
    const { target } = e;
    const value = target.type==='checkbox' ? target.checked : target.value;
    const { name } = target;
    setFormData( f => ({ ...f, [name]: value}));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validFirstName = isEmpty(formData.firstName)  ? setValidInput(`first-name-input-group`) : setInvalidInput(`first-name-input-group`);
    const validLastName  = isEmpty(formData.lastName)   ? setValidInput(`last-name-input-group`)  : setInvalidInput(`last-name-input-group`);
    const validEmail     = isValidEmail(formData.email) ? setValidInput(`email-input-group`)      : setInvalidInput(`email-input-group`);

    if (!validFirstName) return;
    if (!validLastName) return;
    if (!validEmail) return;

    const result = await createUser(formData);

    if (result?.status!==201) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      setIsUpdated(false);
      return;
    }

    setIsUpdated(true);
  };

  const handleClear = () => {
    setFormData({ firstName:'', lastName:'', email:'', isAdmin:false, isAutoEnroller:false, isRevoker:false, isReportViewer:false, isNonWhitelistAccessAllowed:true});
  };

  return (
    <>
      <InputValidation formName='registrationForm' />
      <div className='row'>
        <BackToUserList />
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <Alert alerts={alerts}/>
          <div className='round-border card'>
            <div className='card-header border-bottom-0'>
              <h5>Add a User</h5>
            </div>
            {isUpdated ? (
              <UserRegistrationSuccess
                firstName={formData.firstName}
                lastName={formData.lastName}
              />
            ) : (
              <form
                name='registrationForm'
                method='post'
                onSubmit={(e) => onSubmit(e)}
                noValidate
              >
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-8'>

                      {inputs.map((input) => (
                        <div key={input.name} id={input.group} className='form-label-group' style={{ marginBottom: '20px' }}>
                          <input
                            id={input.id}
                            type='text'
                            className='form-control'
                            placeholder={input.placeholder}
                            name={input.name}
                            value={formData[input.name]}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <label htmlFor={input.id} className='text-left'>
                            {input.label}
                          </label>
                          <div className='invalid-feedback'>
                            {input.placeholder} is a required field
                          </div>
                        </div>
                      ))}

                      {roles.map((role) => (
                        <div key={role.name} className='form-check' style={{ marginBottom: '12px' }}>
                          <input
                            type='checkbox'
                            className='mr-2'
                            name={role.name}
                            checked={formData[role.name]}
                            value={formData[role.name]}
                            onChange={(e) => onChange(e)}
                          />
                          <label className='mb-1'>
                            {role.text}
                          </label>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
                <div className='card-footer border-top-0'>
                  <div className='text-center'>
                    <input
                      type='button'
                      value='Clear'
                      className='btn btn-outline-primary btn-sm mr-3 shadow-none'
                      style={{ width: '40%' }}
                      onClick={handleClear}
                    />
                    <input
                      type='submit'
                      value='Add User'
                      className='btn btn-primary btn-sm shadow-none'
                      style={{ width: '40%' }}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegistration;