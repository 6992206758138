import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import PasswordInput from '../common/PasswordInput';
import InputValidation from '../validation/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidPassword } from '../../validation-utils/validationCheck';
import { setValidPasswordInput, setInvalidPasswordInput } from '../../validation-utils/toggleValidationFeedback';
import { changePassword, logout } from '../../utils/services';

const ChangePassword = () => {
  const history = useHistory();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formData, setFormData] = useState({
    currentPassword: ``,
    newPassword: ``,
    confirmPassword: ``,
  });
  const { currentPassword, newPassword, confirmPassword } = formData;
  const [alerts, setAlerts] = useState([]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validCurrentPassword = isValidPassword(currentPassword);
    const validNewPassword     = isValidPassword(newPassword);
    const validConfirmPassword = isValidPassword(confirmPassword);

    if (validCurrentPassword) setValidPasswordInput(`current-password-group`);
    else setInvalidPasswordInput(`current-password-group`);

    if (validNewPassword) setValidPasswordInput(`new-password-group`);
    else setInvalidPasswordInput(`new-password-group`);

    if (validConfirmPassword) setValidPasswordInput(`confirm-password-group`);
    else setInvalidPasswordInput(`confirm-password-group`);

    if (!validCurrentPassword) return;
    if (!validNewPassword) return;
    if (!validConfirmPassword) return;

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setInvalidPasswordInput(`confirm-password-group`);
      return;
    }

    setPasswordsMatch(true);

    const result = await changePassword(currentPassword, newPassword);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 6000);
      return;
    }

    logout();
    history.push('/change-password-success');
  };

  const handleClear = () => {
    setFormData({ currentPassword: ``, newPassword: ``, confirmPassword: `` });
  };

  return (
    <>
      <InputValidation formName='changePasswordForm' />
      <div className='round-border card'>
        <div className='card-header border-bottom-0'>
          <h5>Password</h5>
        </div>
        <form
          name='changePasswordForm'
          onSubmit={(e) => onSubmit(e)}
          method='post'
          noValidate
        >
          <div className='card-body'>
            <div className='text-left' style={{ position: 'relative' }}>
              <Alert alerts={alerts}/>
            </div>
            <h6 style={{ marginBottom: '25px' }}>Change Your Password</h6>
            <div
              id='current-password-group'
              className='form-group'
              style={{ width: '50%', marginBottom: '20px' }}
            >
              <PasswordInput
                id='current-password-input'
                type='text'
                className='form-control'
                aria-label='Password'
                name='currentPassword'
                placeholder='Enter current password'
                autoComplete='off'
                value={currentPassword}
                onChange={onChange}
                required
                invalidMessage={`Current password is required`}
              />
            </div>
            <div
              id='new-password-group'
              className='form-group'
              style={{ width: '50%', marginBottom: '20px' }}
            >
              <PasswordInput
                id='new-password-input'
                name='newPassword'
                placeholder='Enter new password'
                style={{ width: '50%' }}
                value={newPassword}
                onChange={onChange}
                required
                invalidMessage={`New password is required`}
              />
            </div>
            <div
              id='confirm-password-group'
              className='form-group'
              style={{ width: '50%', marginBottom: '20px' }}
            >
              <PasswordInput
                id='confirm-password-input'
                name='confirmPassword'
                placeholder='Confirm new password'
                value={confirmPassword}
                onChange={onChange}
                required
                invalidMessage={
                  passwordsMatch
                    ? `Confirm password is required`
                    : `Confirm password must match new password`
                }
              />
            </div>
          </div>
          <div className='card-footer border-top-0'>
            <div className='text-center'>
              <input
                type='button'
                value='Clear'
                className='btn btn-outline-primary btn-sm shadow-none mr-3'
                style={{ width: '40%' }}
                onClick={handleClear}
              />
              <input
                type='submit'
                value='Save'
                className='btn btn-primary shadow-none btn-sm'
                style={{ width: '40%' }}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;