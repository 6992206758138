import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import Alert from '../layout/Alert';
import PasswordInput from '../common/PasswordInput';
import InputValidation from '../validation/InputValidation';

// Utils
import
{ isValidEmail
, isValidPassword
} from '../../validation-utils/validationCheck';
import
{ setValidInput
, setInvalidInput
, setValidPasswordInput
, setInvalidPasswordInput
} from '../../validation-utils/toggleValidationFeedback';
import { setAccessToken } from '../../utils/session';
import { login } from '../../utils/services';
import { gSubdomain, ctxValue, getRelyingParty } from '../../utils/config';
import { revision } from '../../utils/revision';

// Styles
import styles from './Login.module.css';

const Login = () => {
  const history = useHistory();
  const { relyingParty, setRelyingParty } = useRelyingParty();
  const [ formData, setFormData] = useState({ email: ``, password: `` });
  const { email, password } = formData;
  const { instance } = useMsal();
  const [ alerts, setAlerts ] = useState([]);

  //console.debug(`Login() relyingParty: ${JSON.stringify(relyingParty,null,3)}`);

  useEffect(() => {
    async function __getRelyingPartyInfo() {
      const rpResult = await getRelyingParty();
      setRelyingParty(rpResult?.data);
      const rpName = rpResult?.data?.name;
      const env = ctxValue('ENV');
      const whitelistSite = window.location.href.startsWith('https://admin2') ? ' * ' : '';
      const title = (env==='PROD') 
                  ? `IDgo Admin - ${rpName}${whitelistSite}` 
                  : `IDgo Admin - ${rpName}${whitelistSite} ${env}`
                  ;
      document.title = title;
    };
    if (relyingParty?.name) return;
    __getRelyingPartyInfo();
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * IDgo's legacy access tokens are more ID tokens than access tokens.
   * In order to support the legacy token approach, we need to treat the AAD idTokens as cozera access tokens.
   * So the 'idToken' is being cached as an access token, then used when calling IDgo micro-services.
   */
  const msalLogin = async () => {
    try {
      const result = await instance.loginPopup();
      const {idToken} = result;
      console.debug(`msalLogin() idToken:${idToken}`);
      // no need to store the AAD token, masl takes care of this for us.
      setAccessToken('aad', undefined);
      history.push({ pathname: '/user-list', hash: gSubdomain })
    } catch (err) {
      const parts = err?.errorMessage.split(/\r?\n/);
      setAlerts([...alerts, {heading: 'Hang On!', msg:parts[0], type:'danger'}]);
      setTimeout(() => setAlerts([]), 10000);
      console.warn(`Login.msalLogin() login.Popup exception: ${parts[0]}`);
    }
  };

  const onLocalAccountLogin = async (e) => {
    e.preventDefault();

    const validEmail = isValidEmail(email);
    const validPassword = isValidPassword(password);

    if (validEmail) setValidInput(`email-input-group`);
    else setInvalidInput(`email-input-group`);

    if (validPassword) setValidPasswordInput(`password-input-group`);
    else setInvalidPasswordInput(`password-input-group`); 

    if (!validEmail) return;
    if (!validPassword) return;

    const result = await login(email, password);
    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 6000);
      return;
    }
    
    history.push({ pathname: '/user-list', hash: gSubdomain });
  };

  return (
    <>
      <InputValidation formName='loginForm' />
      <div className={'row justify-content-center align-items-center ' + styles.loginForm}>
        <div className={'card mx-auto ' + styles.loginSubForm1}>
          <div className={'text-center mx-auto ' + styles.loginSubForm2}>
            <div className='card-body'>
              <div className={styles.loginFormHeader}>
                <Alert alerts={alerts} />
                <h6>IDgo Admin - {relyingParty?.name}</h6>
                <p className={styles.signInMsg}>Sign in to your account</p>
              </div>
              <form
                name='loginForm'
                onSubmit={(e) => onLocalAccountLogin(e)}
                method='post'
                noValidate
              >
                <div id='email-input-group' className={'form-label-group ' + styles.inputGroup}>
                  <input
                    id='email'
                    type='email'
                    aria-label='Email'
                    name='email'
                    value={email}
                    onChange={(e) => onChange(e)}
                    className='form-control'
                    placeholder='Email address'
                    autoComplete='off'
                    required
                    maxLength='128'
                    autoFocus
                  />
                  <label htmlFor='email' className='text-left'>
                    Email address
                  </label>
                  <div className='invalid-feedback'>
                    Please enter a valid email address
                  </div>
                </div>
                <div id='password-input-group' className={'form-group ' + styles.inputGroup}>
                  <PasswordInput
                    id='password-input'
                    value={password}
                    name='password'
                    placeholder='Password'
                    onChange={onChange}
                    required
                    invalidMessage={`Password field is required`}
                  />
                </div>
                <input
                  className='btn btn-primary btn-lg shadow-none'
                  type='submit'
                  value='Sign In'
                />
              </form>
              <div>
                <Link to={'/forgot-password#'+gSubdomain}>
                  <small className='btn-link'>Forgot your password?</small>
                </Link>
              </div>
              { relyingParty?.hasAADTenantId ?
                <div className={styles.enterpriseLoginLink} onClick={() => msalLogin()}>Sign-in with your Enterprise account</div>
                : ''
              }
            </div>
          </div>
          <span className='revision-str'>{revision}</span>
        </div>
      </div>
    </>
  );
};

export default Login;