import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ctxValue } from './config';
import { revision } from './revision';
import 
{ getAccessToken
, setAccessToken
, getRefreshToken
, setRefreshToken
, setUser
} from '../utils/session';

const subdomain = ctxValue('SUBDOMAIN');
const identity_svc = ctxValue('IDENTITY_SERVICE');
const user_auth_svc = ctxValue('USER_AUTH_SERVICE');
const unknownErrorMsg = 'Unknown error';

const _minutesFromUTC = () => {
  const now = new Date();
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const diffMilliseconds = now.getTime() - utcNow.getTime();
  return (diffMilliseconds / 60000);
};

const _request403Retry = async (method, url, body) => {
  let retried=false;
  async function run() {
    const {token} = await getAccessToken();
    const axiosMethod = {get:axios.get, post:axios.post, delete:axios.delete}[method];
    const params = (method==='post') ? [url, body] : [url];
    let config = (method==='delete' && body) 
      ? { headers: {'Authorization': `Bearer ${token}`, 'x-client':`IDgo Admin ${revision}`}, data: body }
      : { headers: {'Authorization': `Bearer ${token}`, 'x-client':`IDgo Admin ${revision}`} }
      ;
    return axiosMethod(...params, config)
      .catch(err => {
        if (err?.response?.status===403) {
          if (retried) {
            alert(`Sorry, your session has expried and you need to Sign In again.`)
            window.location.replace(`/#${subdomain}`);
            return {...err.response, status:403, message: err?.response?.data?.message || err?.message || unknownErrorMsg};
          }
          retried=true;
          return _refreshAccessToken().then(run);
        }
        if (err.response?.status===404) {
          return {...err.response, status:404, message: err?.response?.data?.message || err?.message || unknownErrorMsg};
        }
        return {...err.response, status:500, message: err?.response?.data?.message || err?.message || unknownErrorMsg};
      });
  }
  return run();
};

const _refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    console.info('services._refreshAccessToken() no refreshToken');
    return;
  }
  try {
    const decodedToken = jwtDecode(refreshToken);
    const result = await axios.get(
      `${user_auth_svc}/v1/auth/refresh/${decodedToken?.agentId}`,
      { headers: {'Authorization': `Bearer ${refreshToken}`} }
    );
    setAccessToken('cozera', result.data.accessToken);
    setRefreshToken(result.data.refreshToken);
    setUser(result.data.user);
  } catch (err) {
    console.error('services._refreshAccessToken() error: %o', err);
  }
};

const login = async (email, password) => {
  const result = await axios.post( `${user_auth_svc}/v1/auth`, { email, password, subdomain }, { headers: { 'Content-Type': 'application/json' } })
    .catch(err => {
      if (err?.response?.status===429) {
        return {status:500, message: 'Account locked out, please wait 5 minutes before trying again.'};
      }
      const remainingAttempts = err?.response?.headers['x-ratelimit-remaining'];
      if (remainingAttempts && remainingAttempts === '0') {
        return {status:500, message: 'Too many failed attempts, please try again after 5 minutes.'};
      }
      return {status:500, message: err?.response?.data?.message || err?.message || unknownErrorMsg}
    });

  if (result.status===200) {
    setAccessToken('cozera', result.data.accessToken);
    setRefreshToken(result.data.refreshToken);
    setUser(result.data.user);
  }
  return result;
};

const logout = () => {
  //setAccessToken(undefined);
  //setRefreshToken(undefined);
  //setUser(undefined);
};

const changePassword = async (oldPassword, newPassword) => {
  const body = { oldPassword, newPassword };
  return _request403Retry('post', `${user_auth_svc}/v1/users/update/password`, body);
};

const resetPassword = async (agentId, token, newPassword) => {
  const body = { newPassword };
  return _request403Retry('post', `${user_auth_svc}/v1/users/reset/password/${agentId}/${token}`, body);
};

const forgotPassword = async (email) => {
  const body = { email };
  return _request403Retry('post', `${user_auth_svc}/v1/users/reset/password/${subdomain}`, body);
};

const changeEmail = async (newEmail) => {
  const body = { email: newEmail };
  return _request403Retry('post', `${user_auth_svc}/v1/users/update/email`, body);
};

const getUsers = () => {
  return _request403Retry('get', `${user_auth_svc}/v1/users/${subdomain}`);
};

const createUser = (user) => {
  const body = { ...user, subdomain };
  return _request403Retry('post', `${user_auth_svc}/v1/users`, body);
};

const deleteUser = (agentId) => {
  const body = { agentId };
  return _request403Retry('delete', `${user_auth_svc}/v1/users/delete`, body);
};

const userUpdate = (user) => {
  return _request403Retry('post', `${user_auth_svc}/v1/users/update`, user);
};

const getAuthentications = async (startDate, endDate) => {
  const minutesFromUTC = _minutesFromUTC();
  return _request403Retry('get', `${identity_svc}/event/clientEvent/verifications/v1/${subdomain}?startDatetime=${startDate}&endDatetime=${endDate}&minutesFromUTC=${minutesFromUTC}`);
};

const getAgentEnrollAndFirstAuth = async (startDate, endDate) => {
  return _request403Retry('get', `${identity_svc}/event/clientEvent/agentEnrollAndFirstAuth/v1/${subdomain}?startDatetime=${startDate}&endDatetime=${endDate}`);
};

const getDocumentScanActivity = async (startDate, endDate) => {
  return _request403Retry('get', `${identity_svc}/event/clientEvent/claimDocScanActivity/v1/${subdomain}?startDatetime=${startDate}&endDatetime=${endDate}`);
};

const getEnrollmentActivity = async (startDate, endDate) => {
  return _request403Retry('get', `${identity_svc}/event/clientEvent/enrollmentActivity/v1/${subdomain}?startDatetime=${startDate}&endDatetime=${endDate}`);
};

const getPendingEnrollments = async (startDate, endDate) => {
  return _request403Retry('get', `${identity_svc}/event/clientEvent/pendingEnrollments/v1/${subdomain}?startDatetime=${startDate}&endDatetime=${endDate}`);
};

export 
{ login
, logout
, changePassword
, resetPassword
, forgotPassword
, changeEmail
, getUsers
, createUser
, deleteUser
, userUpdate
, getAuthentications
, getAgentEnrollAndFirstAuth
, getDocumentScanActivity
, getEnrollmentActivity
, getPendingEnrollments
}